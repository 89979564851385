import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

declare module '@mui/material/Divider' {
  export interface DividerPropsVariantOverrides {
    dashed: true;
  }
}

// ----------------------------------------------------------------------

export default function Divider(theme: Theme) {
  return {
    MuiDivider: {
      variants: [
        {
          props: { variant: 'dashed' },
          style: {
            borderStyle: 'dashed',
          },
        },
      ],
    },
  };
}
