import { Controller, useFormContext } from 'react-hook-form';
import { TextFieldProps } from '@mui/material';
import { DatePicker, DatePickerProps } from './DatePicker';

export type RHFDatePickerProps = Omit<DatePickerProps<Date>, 'value' | 'onChange'> & {
  name: string;
  label?: string;
  format?: string;
  TextFieldProps?: Omit<TextFieldProps, 'onBlur'>;
};

export const RHFDatePicker = (props: RHFDatePickerProps) => {
  const { name, label, format = 'dd.MM.yyyy', TextFieldProps, ...datePickerProps } = props;
  const { placeholder = 'дд.мм.гггг', ...restOfTextFieldProps } = TextFieldProps ?? {};

  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onBlur, ...restFieldProps }, fieldState: { error } }) => (
        <DatePicker
          {...restFieldProps}
          {...datePickerProps}
          format={format}
          slotProps={{
            textField: {
              ...restOfTextFieldProps,
              placeholder,
              label: label,
              error: !!error,
              helperText: error?.message ?? '',
              onBlur: onBlur,
            },
          }}
        />
      )}
    />
  );
};
