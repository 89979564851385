import { ValueOf } from '@/@types/app';

export const searchHintNames = {
  distributors: 'distributors',
  orders: 'orders',
  nomenclature: 'nomenclature',
  viewClassificationsNomenclatures: 'viewClassificationsNomenclatures',
  unbondedNomenclature: 'unbondedNomenclature',
  contractors: 'contractors',
  bonded: 'bonded',
  inn: 'inn',
  makers: 'makers',
  classifications: 'classifications',
  promoProducts: 'promoProducts',
  promoContracts: 'promoContracts',
  contractTemplatesDrugs: 'contractTemplatesDrugs',
  contractTemplates: 'contractTemplates',
  templateContractors: 'templateContractors',
  registryBinding: 'registryBinding',
  referencePriceRegistry: 'referencePriceRegistry',
  contractorsDistribution: 'contractorsDistribution',
  planogram: 'planogram',
} as const;

export type SearchHintName = ValueOf<typeof searchHintNames>;

export const localStorageNames = {
  searchHints: 'searchHints',
  useCartStore: 'useCartStore',
  dorimBaseTokens: 'dorim-base:tokens',
  dorimPriceTokens: 'dorim-price:tokens',
} as const;

export type SearchHintsLocalStorageScheme = Partial<Record<SearchHintName, string[] | undefined>>;

export interface LocalStorageScheme {
  [localStorageNames.searchHints]?: SearchHintsLocalStorageScheme;

  // TODO: change types
  [localStorageNames.useCartStore]?: any;
  [localStorageNames.dorimBaseTokens]?: string;
  [localStorageNames.dorimPriceTokens]?: string;
}

export const LOCAL_STORAGE_SCHEME: LocalStorageScheme = {};

export const SEARCH_HINT_MAX_COUNT = 5;
