import { httpClient } from '@/libs/axios/http-client';
import { GroupsResponse } from './types';

const endpoints = {
  profile: '/groups',
};

export const groupsApi = {
  groups: async () => {
    const { data } = await httpClient.get<GroupsResponse>(endpoints.profile);

    return data;
  },
};
