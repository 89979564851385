import { paramsToQueryKey } from '@/helpers/transformation.helpers';
import {
  FetchContractDocumentParams,
  FetchDrugAnalyticsParams,
  FetchPromoContractParams,
  PromoContractsFetchParams,
  FetchMarketingOrgAgreementParams,
  FetchDrugOrdersParams,
} from './promo-contracts.api';

export const PREFIX = 'promo-contracts' as const;

export const PromoContractsQueryKeys = {
  root: [PREFIX],

  getContractsKey: (params?: PromoContractsFetchParams) => paramsToQueryKey([PREFIX, 'list'], params),
  getContractKey: (params?: FetchPromoContractParams) => paramsToQueryKey([PREFIX, 'contract'], params),
  getDrugAnalyticsKey: (params?: FetchDrugAnalyticsParams) => paramsToQueryKey([PREFIX, 'drug-analytics'], params),
  getDrugOrdersKey: (params?: FetchDrugOrdersParams) => paramsToQueryKey([PREFIX, 'drug-orders'], params),
  getContractDocumentKey: (params?: FetchContractDocumentParams) =>
    paramsToQueryKey([PREFIX, 'contract-document'], params),
  getMarketingOrgAgreementKey: (params: Partial<FetchMarketingOrgAgreementParams>) =>
    paramsToQueryKey([PREFIX, 'marketing-org'], params),
};
