import { Button, ButtonProps, styled } from '@mui/material';

// ----------------------------------------------------------------------

export type SubmenuItemButtonProps = Omit<ButtonProps, 'onClick'> & {
  title: string;
  onClick: () => void;
};

// ----------------------------------------------------------------------

export const StyledRoot = styled(Button)(({ theme }) => ({
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  justifyContent: 'start',
  fontSize: 14,
  fontWeight: 400,
  color: theme.palette.text.primary,

  '&:hover': {
    backgroundColor: '#E6EBF0',
  },
  '&:not(:hover)': {
    backgroundColor: 'transparent',
  },
})) as typeof Button;

// ----------------------------------------------------------------------

export const SubmenuButtonItem = (props: SubmenuItemButtonProps) => {
  const { title, ...restOfButtonProps } = props;

  return <StyledRoot {...restOfButtonProps}>{title}</StyledRoot>;
};
