import { intersection } from 'lodash';

import { DORIM_ADMIN_GROUP_ID, type GroupId } from '@/constants/groups.constants';
import { useGroups } from '@/modules/groups';

export const accessList: Record<string, UseCheckPermissionsParams> = {
  chargeCashback: { allowedGroupIds: [DORIM_ADMIN_GROUP_ID] },
  changeStatus: { allowedGroupIds: [DORIM_ADMIN_GROUP_ID] },
};

type UseCheckPermissionsParams =
  | { allowedGroupIds: GroupId[]; deniedGroupIds?: never }
  | { allowedGroupIds?: never; deniedGroupIds: GroupId[] }
  | { allowedGroupIds?: never; deniedGroupIds?: never };

export const useCheckPermissions = (params: UseCheckPermissionsParams) => {
  const { groups: currentGroups, isFetching: isGroupsFetching } = useGroups();

  if (isGroupsFetching || !currentGroups) return false;

  const currentGroupIds = currentGroups.map(group => group.id);

  if ('allowedGroupIds' in params && params.allowedGroupIds) {
    return intersection(currentGroupIds, params.allowedGroupIds).length > 0;
  }

  if ('deniedGroupIds' in params && params.deniedGroupIds) {
    return intersection(currentGroupIds, params.deniedGroupIds).length === 0;
  }

  return false;
};
