import { PaginatedList, PaginationParams } from '@/api/@types/pagination.types';
import {
  Drug,
  DrugURLResources,
  ReceptionFeature,
  DrugDosage,
  Instruction,
  DrugImageInfo,
  DrugImage,
} from '@/api/domains/drugs.types';
import { RecordStatusId } from '@/constants/record-status.constants';
import { httpClient } from '@/libs/axios/http-client';

// ----------------------------------------------------------------------

export type DrugsSearchParams = PaginationParams & {
  query?: string;
  with_inactive?: boolean;
  for_specification_template?: boolean;
  with_childs?: boolean;
  maker_ids?: Array<number>;
  classification_ids?: Array<number>;
  inn_ids?: Array<number>;
};

export type DeleteControlDrugsParams = PaginationParams & {
  distributor_ids?: number[];
  classification_ids?: string;
  inn_ids?: string;
  maker_ids?: string;
};

export type DrugsDeleteFormData = {
  ids: number[];
};

export type DrugFormData = {
  name?: string;
  category_id?: number;
  maker_id?: number;
  measure_unit_id?: number;
  vat_id?: number;

  form?: string;
  number?: number;
  class_id?: number;
  inn_id?: number;
  ikpu?: number;
  blister_count?: number;
  barcodes?: string[];
  barcode?: number;
  is_receipt?: boolean;

  maker_name?: string;
  country_name?: string;

  description?: string;

  record_status_id?: RecordStatusId;

  license_holder_id?: number;
  release_form_id?: number;
  category_dictionary_id?: number;
  url_resources?: DrugURLResources;
  marketing_org_id?: number;
  brand?: string;
  registration_number?: string;
  registration_start_date?: string;
  registration_end_date?: string;
};

export type DeduplicateDrugParams = {
  main_drug_id: number;
  slave_drug_ids: Array<number>;
};

type DrugStatuses = {
  record_status_ids: RecordStatusId[];
};

type DeleteControlDrugs = PaginatedList<'items', Drug> & {
  count: number;
};

type UpdateDosage = {
  position: number;
  inn_id: number;
  measure_unit_id: number;
  dosage: number;
};

export type UpdateDosagesParams = {
  dosages: Array<UpdateDosage>;
};

export type FetchConflictingDeduplicationDrugsParams = PaginationParams & {
  slave_maker_ids: Array<number>;
};

export type FetchInstructionParams = {
  drug_id: number;
};

export type UpdateInstructionParams = {
  drug_id: number;
  instruction: string;
};

export type FetchDrugImagesParams = {
  drug_id: number;
};

export type UpdateDrugImagesParams = {
  drug_id: number;
  images: Array<DrugImageInfo> | null;
};

type FetchDrugsByDosageInn = {
  inn: number;
};

export const DrugsApi = {
  searchDrugs: async (params: DrugsSearchParams) => {
    const { data } = await httpClient.get<PaginatedList<'items', Drug>>('drugs', {
      params,
    });

    return data;
  },

  deleteControlFetchDrugs: async (params: DeleteControlDrugsParams) => {
    const { data } = await httpClient.get<DeleteControlDrugs>('drugs', {
      params,
    });

    return data;
  },

  fetchDrugsByDosageInn: async (params: FetchDrugsByDosageInn) => {
    const { data } = await httpClient.get<PaginatedList<'items', Drug>>(`drugs/${params.inn}/dosage-inn`);

    return data;
  },

  fetchDrug: async (drugId: number) => {
    const { data } = await httpClient.get<Drug>(`drugs/${drugId}`);

    return data;
  },

  fetchDrugStatusList: async (drugId: number) => {
    const { data } = await httpClient.get<DrugStatuses>(`drugs/${drugId}/available-statuses`);

    return data;
  },

  deleteDrugs: async (formData: DrugsDeleteFormData) => {
    await httpClient.delete<void>('drugs', {
      data: formData,
    });
  },

  createDrug: async (formData: DrugFormData) => {
    const { data } = await httpClient.post<Drug>('drugs', formData);

    return data;
  },

  updateDrug: async (drugId: number, formData: DrugFormData) => {
    const { data } = await httpClient.put<Drug>(`drugs/${drugId}`, formData);

    return data;
  },

  deduplicateDrug: (params: DeduplicateDrugParams) => httpClient.post<void>('drugs/deduplicate', params),

  fetchDosages: async (drugId: number) => {
    const { data } = await httpClient.get<PaginatedList<'items', DrugDosage>>(`drugs/${drugId}/dosages`);

    return data;
  },

  updateDosages: async (drugId: number, params: UpdateDosagesParams) =>
    await httpClient.patch(`drugs/${drugId}/dosages`, params),

  fetchConflictingDeduplicationDrugs: async (mainMakerId: number, params: FetchConflictingDeduplicationDrugsParams) => {
    const { data } = await httpClient.get<PaginatedList<'items', Drug>>(`makers/${mainMakerId}/conflicting-drugs`, {
      params,
    });

    return data;
  },

  fetchInstruction: async ({ drug_id }: FetchInstructionParams) => {
    const { data } = await httpClient.get<Instruction>(`drugs/${drug_id}/instruction`);

    return data;
  },

  updateInstruction: ({ drug_id, ...params }: UpdateInstructionParams) =>
    httpClient.patch<void>(`drugs/${drug_id}/instruction`, params),

  updateReceptionFeatures: async (drugId: number, formData: Array<ReceptionFeature>) => {
    await httpClient.patch<void>(`drugs/${drugId}/reception-features`, { reception_features: formData });
  },

  fetchReceptionFeatures: async (drugId: number) => {
    const { data } = await httpClient.get<PaginatedList<'items', ReceptionFeature>>(
      `drugs/${drugId}/reception-features`,
    );

    return data;
  },

  fetchImages: async ({ drug_id }: FetchDrugImagesParams) => {
    const { data } = await httpClient.get<PaginatedList<'items', DrugImage>>(`drugs/${drug_id}/images`);

    return data;
  },

  updateImages: async ({ drug_id, images }: UpdateDrugImagesParams) => {
    const { data } = await httpClient.put<PaginatedList<'items', DrugImage>>(`drugs/${drug_id}/images`, { images });

    return data;
  },
};
