import { lazy } from 'react';

const EimzoDocumentsPage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "EimzoDocumentsPage" */
      './EimzoDocumentsPage'
    ),
);

export default EimzoDocumentsPage;
