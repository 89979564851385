import { useQuery } from '@tanstack/react-query';
import { groupsApi } from '../groups.api';
import { groupsQueryKeys } from './groupsQueryKeys';

export const useGroups = () => {
  const { isLoading, isFetching, data } = useQuery({
    queryKey: groupsQueryKeys.root,
    queryFn: () => groupsApi.groups(),
    staleTime: Infinity,
    retry: false,
  });

  return { isFetching, isLoading, groups: data?.items };
};
