import {
  HandshakeOutlined as HandshakeOutlinedIcon,
  HubOutlined as HubOutlinedIcon,
  MedicationOutlined as MedicationOutlinedIcon,
  DescriptionOutlined as DescriptionOutlinedIcon,
  HistoryEdu as HistoryEduIcon,
} from '@mui/icons-material';

import { ReactComponent as DorimPromoSVG } from '@/assets/icons-2.0/dorim.promo.svg';

import { SidebarBase } from '@/layouts/Sidebar/SidebarBase';
import { SidebarItem } from '@/layouts/Sidebar/SidebarItem';

import {
  BindingsPaths,
  ClassificationsPaths,
  ContractorsPaths,
  InnPaths,
  MakersPaths,
  NomenclaturePaths,
  OffersPaths,
  PriceListsPaths,
} from '@/router';
import { ReportsPaths } from '@/router/paths/reports.paths';
import { DorimPromoPaths } from '@/router/paths/dorim-promo.path';
import { EimzoDocumentsPath } from '@/router/paths/e-imzo-documents.paths';

export const Sidebar = () => (
  <SidebarBase>
    <SidebarItem
      iconComponent={MedicationOutlinedIcon}
      to={NomenclaturePaths.ROOT_PATH}
      title="Товары"
      submenu={[
        {
          to: NomenclaturePaths.ROOT_PATH,
          title: 'Номенклатура',
        },
        {
          to: MakersPaths.ROOT_PATH,
          title: 'Производители',
        },
        {
          to: ClassificationsPaths.ROOT_PATH,
          title: 'Классификации',
        },
        {
          to: InnPaths.ROOT_PATH,
          title: 'МНН',
        },
        {
          to: BindingsPaths.ROOT_PATH,
          title: 'Привязка товаров',
        },
      ]}
    />
    <SidebarItem
      iconComponent={DescriptionOutlinedIcon}
      to={ReportsPaths.ROOT}
      title="Отчетность"
      submenu={[
        {
          to: ReportsPaths.DISTRIBUTORS.ROOT,
          title: 'Дистрибьюторская отчетность',
        },
      ]}
    />
    <SidebarItem iconComponent={HandshakeOutlinedIcon} to={ContractorsPaths.ROOT_PATH} title="Контрагенты" />

    <SidebarItem
      iconComponent={HubOutlinedIcon}
      to={PriceListsPaths.ROOT_PATH}
      title="Предложения дистр-ов"
      submenu={[
        {
          to: PriceListsPaths.ROOT_PATH,
          title: 'Прайс-листы',
        },
        {
          to: OffersPaths.ROOT_PATH,
          title: 'Акции',
        },
      ]}
    />

    <SidebarItem
      iconComponent={DorimPromoSVG}
      to={DorimPromoPaths.ROOT_PATH}
      title="Dorim.Promo"
      submenu={[
        {
          to: DorimPromoPaths.PROMO_PRODUCTS_PATH,
          title: 'Промо-товары',
        },
        {
          to: DorimPromoPaths.CONTRACT_TEMPLATES_PATH,
          title: 'Шаблоны',
        },
        {
          to: DorimPromoPaths.CONTRACTS_PATH,
          title: 'Спецификации',
        },
        { to: DorimPromoPaths.PLANOGRAM_TASKS_CONTROL, title: 'Контроль заданий' },
      ]}
    />

    <SidebarItem iconComponent={HistoryEduIcon} to={EimzoDocumentsPath.ROOT_PATH} title="E-Imzo" />
  </SidebarBase>
);
