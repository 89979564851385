import { useState } from 'react';
import { useToggle } from 'react-use';
import { CircularProgress, Stack } from '@mui/material';

import { StatusSelect } from '@/components/StatusSelect';
import { ConfirmationWarningDialog } from '@/components/dialogs';
import {
  PromoContractStatusNameByStatusId,
  PROMO_CONTRACT_STATUS_PAYMENT_CALCULATED,
  PROMO_CONTRACT_STATUS_PAYMENT_COMPLETED,
  PROMO_CONTRACT_STATUS_PAYMENT_EXPECTED,
  PromoContractStatusId,
  PROMO_CONTRACT_STATUS_CANCELLED,
  PROMO_CONTRACT_STATUS_CLARIFICATION,
} from '@/constants/promo-contract-status.constants';
import { PromoContractDataGridRowData } from '../../data-grids';
import { getColorByPromoContractStatusId } from '../../helpers/status-select.helpers';
import { useUpdatePromoContract } from '../../data-grids/useUpdatePromoContract';

const getStatuses = (selectedStatusId: PromoContractStatusId): PromoContractStatusId[] => {
  switch (selectedStatusId) {
    case PROMO_CONTRACT_STATUS_PAYMENT_EXPECTED: {
      const paymentOptions: PromoContractStatusId[] = [selectedStatusId, PROMO_CONTRACT_STATUS_PAYMENT_COMPLETED];
      return paymentOptions;
    }
    case PROMO_CONTRACT_STATUS_CLARIFICATION: {
      const clarificationOptions: PromoContractStatusId[] = [
        selectedStatusId,
        PROMO_CONTRACT_STATUS_PAYMENT_CALCULATED,
        PROMO_CONTRACT_STATUS_CANCELLED,
      ];
      return clarificationOptions;
    }
    default: {
      const cashbackOptions: PromoContractStatusId[] = [selectedStatusId, PROMO_CONTRACT_STATUS_PAYMENT_CALCULATED];
      return cashbackOptions;
    }
  }
};

type PromoContractsStatusSelectProps = {
  promoContractDataGridRowData: PromoContractDataGridRowData;
};

export const PromoContractStatusSelect = (props: PromoContractsStatusSelectProps) => {
  const { promoContractDataGridRowData } = props;
  const { promoContract, id } = promoContractDataGridRowData;
  const { record_status_id: selectedRecordStatusId } = promoContract;

  const [isConfirmationDialogOpen, toggleIsConfirmationDialogOpen] = useToggle(false);
  const openConfirmationDialog = () => toggleIsConfirmationDialogOpen(true);
  const closeConfirmationDialog = () => toggleIsConfirmationDialogOpen(false);
  const [newStatusId, setNewStatusId] = useState(selectedRecordStatusId);

  const { mutate: updatePromoContract, isLoading } = useUpdatePromoContract();
  const changeStatus = () => {
    updatePromoContract(
      {
        contractId: id,
        params: {
          record_status_id: newStatusId,
        },
        rowData: promoContractDataGridRowData,
      },
      {
        onSuccess: closeConfirmationDialog,
      },
    );
  };

  const handleSelectStatus = (statusId: PromoContractStatusId) => {
    setNewStatusId(statusId);
    openConfirmationDialog();
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <StatusSelect
          selectedRecordStatusId={selectedRecordStatusId}
          statuses={getStatuses(selectedRecordStatusId)}
          onSelectStatus={handleSelectStatus}
          getLabelName={statusId => PromoContractStatusNameByStatusId[statusId]}
          getLabelColor={getColorByPromoContractStatusId}
          isDisabled={isLoading}
        />

        {isLoading && <CircularProgress size={20} color="inherit" />}
      </Stack>

      <ConfirmationWarningDialog
        open={isConfirmationDialogOpen}
        isLoading={isLoading}
        onProceed={changeStatus}
        onClose={closeConfirmationDialog}
        headerText="Смена статуса"
        bodyText={
          <>
            Изменить статус <b>{PromoContractStatusNameByStatusId[selectedRecordStatusId]}</b> на{' '}
            <b>{PromoContractStatusNameByStatusId[newStatusId]}</b>?
          </>
        }
        proceedButtonText="Изменить"
      />
    </>
  );
};
