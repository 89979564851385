import { type FetchPromoContractParams, PromoContractsApi } from '@/api/domains/promo-contracts.api';
import { PromoContractsQueryKeys } from '@/api/domains/promo-contracts.query-keys';
import { useQuery } from '@tanstack/react-query';

export const usePromoContract = (params?: FetchPromoContractParams) => {
  return useQuery({
    queryKey: PromoContractsQueryKeys.getContractKey(params),
    queryFn: () => {
      if (params && params.contractId) {
        return PromoContractsApi.fetchPromoContract(params);
      }
    },
    refetchOnWindowFocus: false,
    enabled: !!params && !!params.contractId,
  });
};
