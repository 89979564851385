export type GroupId =
  | typeof DORIM_ADMIN_GROUP_ID
  | typeof DORIM_PRICE_FREE_GROUP_ID
  | typeof SALES_MANAGER_GROUP_ID
  | typeof HEAD_OF_SALES_GROUP_ID
  | typeof MED_EXPERT_GROUP_ID
  | typeof LINKS_OPERATOR_GROUP_ID;

export const DORIM_ADMIN_GROUP_ID = 1;
export const DORIM_PRICE_FREE_GROUP_ID = 2;
export const SALES_MANAGER_GROUP_ID = 3;
export const HEAD_OF_SALES_GROUP_ID = 4;
export const MED_EXPERT_GROUP_ID = 5;
export const LINKS_OPERATOR_GROUP_ID = 6;
