import { useState } from 'react';
import { useDebounce } from 'react-use';
import { Autocomplete, AutocompleteProps, Box } from '@mui/material';
import { Maker } from '@/api/domains/makers.types';
import { formatMakerName, highlightKeyword } from '@/helpers/format.helpers';
import { useMakers } from '@/hooks/useMakers';

// ----------------------------------------------------------------------

export type MakerFieldProps = Omit<
  AutocompleteProps<Maker, false, false, false>,
  'freeSolo' | 'autoComplete' | 'includeInputInList' | 'filterSelectedOptions' | 'loading' | 'noOptionsText' | 'options'
>;

// ----------------------------------------------------------------------

export const MakerField = (props: MakerFieldProps) => {
  const [inputValue, setInputValue] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  useDebounce(() => setSearchQuery(inputValue), 500, [inputValue]);

  const { isInitialLoading, makers } = useMakers(
    {
      limit: 50,
      query: searchQuery,
    },
    {
      enabled: searchQuery.length >= 2,
    },
  );

  const handleInputChange = (event: React.SyntheticEvent, inputValue: string) => {
    setInputValue(inputValue);
  };

  return (
    <Autocomplete
      {...props}
      autoComplete
      includeInputInList
      loading={isInitialLoading}
      options={makers}
      getOptionLabel={option =>
        typeof option === 'string' ? option : formatMakerName(option.name, option.country_name)
      }
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onInputChange={handleInputChange}
      renderOption={(optionProps, option) => (
        <Box
          component="li"
          {...optionProps}
          sx={{
            display: 'block !important',
            margin: 0,
            padding: 0,
          }}
        >
          {highlightKeyword(formatMakerName(option.name, option.country_name), searchQuery)}
        </Box>
      )}
    />
  );
};
