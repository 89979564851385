import { format } from 'date-fns';
import parseHtml from 'html-react-parser';
import parsePhoneNumber from 'libphonenumber-js';

import type { Address } from '@/api/domains/addresses.types';
import type { Region } from '@/api/domains/regions.types';
import type { City } from '@/api/domains/cities.types';
import type { Info } from '@/api/domains/info.types';
import { DEFAULT_DATE_TIME_FORMAT } from '@/constants/date-formats.constants';

// ----------------------------------------------------------------------

export const formatDrugNumber = (value: number | string) => (Number(value) > 0 ? `№${value}` : '');

// ----------------------------------------------------------------------

export const formatDrugName = (name: string, form: string, number: number | string) =>
  `${name} ${form} ${formatDrugNumber(number)}`.trim();

// ----------------------------------------------------------------------

export const formatMakerName = (name: string, countryName?: string) =>
  countryName ? `${name} (${countryName})` : name;

export const formatLicenseHolderName = (name: string, countryName?: string) =>
  countryName ? `${name} (${countryName})` : name;

// ----------------------------------------------------------------------
export const formatDateTime = (date: string | number | Date, dateFormat: string = DEFAULT_DATE_TIME_FORMAT) => {
  try {
    return format(new Date(date), dateFormat);
  } catch {
    return String(date);
  }
};

// ----------------------------------------------------------------------

export const formatNumberByThousands = (value: string | number) => new Intl.NumberFormat('ru-RU').format(Number(value));

// ----------------------------------------------------------------------

const HIGHLIGHT_COLOR = 'yellow';
const REGEXP_SPECIAL_CHAR = /[!#$%^&*)(+=.<>{}[\]:;'"|~`_-]/g;

export const highlightKeyword = (source: string, keyword: string, highlightColor: string = HIGHLIGHT_COLOR) => {
  const escapedKeyword = keyword.replace(REGEXP_SPECIAL_CHAR, '\\$&');

  const preparedSource = source.replace(
    getSearchPattern(escapedKeyword),
    `<span style="background-color: ${highlightColor}">$&</span>`,
  );

  return parseHtml(preparedSource);
};

// ----------------------------------------------------------------------

export const preparePhoneNumberDisplayValue = (phoneNumber: number | string) => {
  const sanitizedPhoneNumber = phoneNumber.toString().replace(/\D/g, '');
  const parsedPhoneNumber = parsePhoneNumber(`+${sanitizedPhoneNumber}`);

  return parsedPhoneNumber ? parsedPhoneNumber.formatInternational() : '';
};

// ----------------------------------------------------------------------

export const getSearchPattern = (keyword: string) => {
  const regExpString = keyword
    .trim()
    .replace(/[^а-яА-ЯёЁa-zA-Z0-9]+/g, ').*?(')
    .replace(/[её]/gi, '(?:е|ё)');

  return new RegExp(`(${regExpString})`, 'i');
};

// ----------------------------------------------------------------------

export const parseRegion = (region: Region) => region.name;
export const parseCity = (city: City) =>
  `${city.settlement_type ? city.settlement_type.name : ''} ${city.full_name}`.trim();
export const parseStreet = (street: string, streetType: Info) => `${streetType.name} ${street}`.trim();
export const parseHouse = (house: string) => `д. ${house}`;
export const parseBuilding = (building: string) => `корп. ${building}`;
export const parseApartment = (apartment: string) => `кв. ${apartment}`;

export const parseAddress = (address: Address) => {
  const { region, city, street_type, street, building, house, apartment } = address;

  return {
    region: region ? parseRegion(region) : null,
    city: city ? parseCity(city) : null,
    street: street && street_type ? parseStreet(street, street_type) : null,
    house: house ? parseHouse(house) : null,
    building: building ? parseBuilding(building) : null,
    apartment: apartment ? parseApartment(apartment) : null,
  };
};

export const prepareAddressDisplayValue = (address: Address | null) => {
  if (!address) {
    return '';
  }

  const { city, street, house, building, apartment } = parseAddress(address);

  return [city, street, house, building, apartment].filter(Boolean).join(', ');
};

// ----------------------------------------------------------------------

/**
 * Provides correct endings to words coming after numbers.
 */
export const formatWordEnding = (number: number, titles: [string, string, string]) => {
  if (number % 10 === 1 && number % 100 !== 11) {
    return titles[0];
  }

  if (number % 10 >= 2 && number % 10 <= 4 && (number % 100 < 10 || number % 100 >= 20)) {
    return titles[1];
  }

  return titles[2];
};

/**
 * Formatted UZ phone number
 * phoneNumber: number | string = '998001112233'
 * return: +998 (00) 111-22-33
 * the number of digits is less than 12 then the same parameter value that was passed is returned
 * */
export const phoneNumberFormat = (phoneNumber: number | string) => {
  const sanitizedPhoneNumber = phoneNumber.toString().replace(/\D/g, '');

  return sanitizedPhoneNumber.length === 12
    ? sanitizedPhoneNumber.replace(/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3 $4 $5')
    : sanitizedPhoneNumber;
};
