import { httpClient } from '@/libs/axios/http-client';
import type { MigrateContractorParams, Profile, UpdateProfileRequestParams, User } from './profile.types';

export const ProfileApi = {
  fetchProfile: async () => {
    const { data } = await httpClient.get<Profile>('profile');

    return data;
  },

  updateProfile: async (params: UpdateProfileRequestParams) => {
    const { data } = await httpClient.patch<Profile>('profile', params);

    return data;
  },

  updateProfileById: async (id: number, params: UpdateProfileRequestParams) => {
    const { data } = await httpClient.patch<Profile>(`profile/${id}`, params);

    return data;
  },

  blockProfile: (id: number) => httpClient.put<Profile>(`profile/${id}/block`),

  fetchProfileOffer: async () => {
    const { data } = await httpClient.put<User>('profile/offer');

    return data;
  },

  migrateUser: ({ nextContractorId, profileIds }: MigrateContractorParams) =>
    httpClient.put<void>('profile/move', { to_contractor_id: nextContractorId, ids: profileIds }),
};
